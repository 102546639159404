const generateClassInfoFromFormData = (
  formData,
  processed_date,
  billing_amount,
  getScheduleFromId
) => {
  const classInfo = { ...formData, processed_date, billing_amount };

  [1, 2, 3, 4, 5].forEach(val => {
    const class_level = classInfo[`class_level_${val}`] || "";
    if (class_level) {
      const { start_time, end_time } = getScheduleFromId(class_level) || {};
      classInfo[`start_time_${val}`] = start_time;
      classInfo[`end_time_${val}`] = end_time;
    }
  });

  return classInfo;
};

export default generateClassInfoFromFormData;
