import { useCallback, useEffect, useState } from "react";
import RegularClassScheduleContext from "src/context/regularClassScheduleContext.js";
import { classScheduleAll } from "src/services/privateApi";
import messages from "src/utils/messages";
import { swalError } from "src/utils/swalHelpers";
import { daysOfWeek, dummySchedule } from "src/utils/constants";
import createArrayFromSize from "src/utils/creatArrayFromSize";
import _ from "lodash";

const defaultClassSchedule = () => {
  return daysOfWeek.map(day => ({
    day,
    classes: createArrayFromSize(3, dummySchedule()),
  }));
};

const RegularClassScheduleProvider = ({ children, ...props }) => {
  const [list, setList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch regular classes
        const classScheduleResponse = await classScheduleAll();

        if (classScheduleResponse.resType === 1) {
          setList(classScheduleResponse.data.data);
        } else if (classScheduleResponse.resType === 2) {
          await swalError(messages.classScheduleNotFound);
        } else if (classScheduleResponse.resType === 3) {
          await swalError(messages.contactMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        await swalError(messages.contactMessage);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchData();
  }, []);

  const getSchedule = (regularClassId, _list = list) => {
    return _list.find(val => val.regular_class_id === regularClassId)
      ?.classSchedule;
  };

  const generateScheduleTableData = (regularClassId, list) => {
    let classSchedule = defaultClassSchedule();

    if (regularClassId) {
      const selectedClassSchedule = getSchedule(regularClassId, list);
      _.merge(classSchedule, selectedClassSchedule);
    }

    const maxValuesClassLength = Math.max(
      ...classSchedule.map(val => val.classes.length)
    );

    const tableData = classSchedule
      .find(val => val.classes.length === maxValuesClassLength)
      .classes.map((_, slotIndex) => ({
        slot: slotIndex + 1,
        schedules: classSchedule.map(dayEntry => {
          return {
            day: dayEntry.day,
            ...dayEntry.classes[slotIndex], // Spread class details
          };
        }),
      }));

    return tableData;
  };

  const daysOptionList = regularClassId => {
    const schedule = getSchedule(regularClassId);

    const options = [{ value: "no-value", label: "曜日を選択してください" }];

    if (schedule) {
      schedule.forEach(val => {
        if (val.classes.some(val => val.start_time)) {
          options.push({
            ...val,
            value: val.day,
            label: val.day,
          });
        }
      });
    }

    return options;
  };

  const getScheduleFromId = id => {
    return list
      .map(val => val.classSchedule)
      .flat()
      .map(val => val.classes)
      .flat()
      .find(val => val.id === parseInt(id));
  };

  const contextValues = {
    list,
    isLoaded,
    setList,
    getSchedule,
    generateScheduleTableData,
    daysOptionList,
    getScheduleFromId,
  };

  return (
    <RegularClassScheduleContext.Provider value={contextValues}>
      {children}
    </RegularClassScheduleContext.Provider>
  );
};

export default RegularClassScheduleProvider;
