const generateOptions = list => {
  return list.map(val => {
    return (
      <option key={val.value} value={val.value}>
        {val.label}
      </option>
    );
  });
};

export default generateOptions;
