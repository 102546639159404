import { useEffect, useState } from "react";
import Nvh from "src/components/common/Nvh";
import useRegularClass from "src/hooks/useRegularClass";

const bgActiveStyle = {
  backgroundColor: "#d4dfee",
};

const ClassDetails = ({
  monthLabel,
  isSelected,
  date,
  classes,
  updatedClasses,
  setUpdatedClasses,
  textInputRef,
}) => {
  const [selectedTab, setSelectedTab] = useState(classes[0]?.id);

  const regularClass = useRegularClass();

  const { getAffiliationName } = regularClass;

  const updatedClass = updatedClasses.find(val => val.id === selectedTab) || {};

  const classInfo = classes.find(val => val.id === selectedTab) || {};

  const getRank = () => {
    const { processed_date: updatedDate, class: updatedRank } = updatedClass;
    const { processed_date: thisDate, class: thisRank } = classInfo;
    return updatedDate === thisDate ? updatedRank : thisRank || "";
  };

  const dayTimeValue = () => {
    return [1, 2, 3, 4, 5]
      .map(i => {
        const dayWeek = classInfo?.[`day_week_${i}`];
        const startTime = classInfo?.[`start_time_${i}`];
        const endTime = classInfo?.[`end_time_${i}`];

        if (dayWeek) {
          const day = Nvh({ value: dayWeek });
          const start = Nvh({ value: startTime });
          const end = Nvh({ value: endTime });
          return `${day}(${start}-${end})`;
        }

        return "";
      })
      .filter(Boolean)
      .join(" ");
  };

  const handleRankChange = e => {
    const newUpdatedClass = { ...classInfo, class: e.target.value };

    const newUpdatedClasses = updatedClasses.filter(
      val => val.id !== classInfo.id
    );

    newUpdatedClasses.push(newUpdatedClass);

    setUpdatedClasses(newUpdatedClasses);
  };

  const disabled = !isSelected || Object.keys(classInfo).length <= 2;

  const handleClassToggle = id => {
    setSelectedTab(id);
  };

  // Render buttons for class data
  const renderClassButtons = () => {
    return classes.map(item => {
      const buttonClassName =
        "mb-0 border btn shadow-md mr-5" +
        (selectedTab === item?.id
          ? " btn-primary fw-bold"
          : " bg-light opacity-9");

      return (
        <button
          key={item.id}
          className={buttonClassName}
          type="button"
          onClick={() => handleClassToggle(item.id)}
          tabIndex={-1}
        >
          {getAffiliationName(item?.regular_class_id)}
        </button>
      );
    });
  };

  return (
    <div
      className={`${isSelected ? "row m-2 shadow rounded p-2" : "row m-2 p-2"}`}
      style={isSelected ? bgActiveStyle : {}}
    >
      <div className="col-md-2 d-flex align-items-start flex-column">
        <div>{monthLabel}:</div>
        <div>{date}</div>
      </div>
      <div className="col-md-10">
        <div style={{ maxWidth: "120px" }} className="current-rank">
          {renderClassButtons()}
        </div>
        <div className="right-bottom-container col-md-12 mt-1 border p-1">
          <div className="d-flex justify-content-start">
            <div className="col-md-6 p-2">
              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  所属:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    name="affiliation"
                    value={getAffiliationName(classInfo?.regular_class_id)}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  曜日時間:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    name="day_time"
                    value={dayTimeValue()}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 p-2 pb-0">
              <span>回数：</span>
              <input
                type="text"
                className="w-25 mr-5"
                value={classInfo?.times || ""}
                disabled
              />
              <span>級：</span>
              <input
                type="text"
                className="w-25"
                onChange={handleRankChange}
                value={getRank()}
                disabled={disabled}
                ref={textInputRef}
                onFocus={e => {
                  e.target.select();
                }}
              />
              <span>（50：特習）</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassDetails;
