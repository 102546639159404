import { useCallback, useEffect, useRef, useState } from "react";
import StudentClassContext from "src/context/studentClassContext";
import useClassLevel from "src/hooks/useClassLevel";
import useRegularClass from "src/hooks/useRegularClass";
import useNav from "src/hooks/useNav";
import { postClasses } from "src/services/privateApi";
import createArrayFromSize from "src/utils/creatArrayFromSize";
import deepCopy from "src/utils/deepCopy";
import { dateFormat } from "src/utils/formatdate";
import generateClassFormListInfo from "src/utils/generateClassFormListInfo";
import subtractMonths from "src/utils/subtractMonths";
import { swalError } from "src/utils/swalHelpers";
import useRegularClassSchedule from "src/hooks/useRegularClassSchedule";
import useTuitionFees from "src/hooks/useTuitionFees";
import { maxAllowedClasses } from "src/utils/constants";

const StudentClassProvider = ({ children, ...props }) => {
  const classLevel = useClassLevel();
  const regularClass = useRegularClass();
  const regularClassSchedule = useRegularClassSchedule();
  const tuitionFees = useTuitionFees();
  const { currentMonth } = useNav();

  const { memberData, readOnly, setReadOnly } = props;

  const tableFilterButtonRef = useRef(null);

  const [classList, setClassList] = useState([]);
  const [formList, setFormList] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [billingAmount, setBillingAmount] = useState(null);

  // note praveen keep this in sync with other states!
  const [date, setDate] = useState(currentMonth);

  let formData = formList.find(val => val.id === selectedClassId);
  formData = formData ? deepCopy(formData) : {};

  const disableForm =
    props.readOnly ||
    !formList[0]?.id ||
    !classLevel.isLoaded ||
    !regularClass.isLoaded ||
    !regularClassSchedule.isLoaded ||
    !tuitionFees.isLoaded;

  const getTotalFees = useCallback(
    formList => {
      return formList.reduce((acc, curr) => {
        const { closed_flag, times, regular_class_id } = curr;

        const tuition = tuitionFees.list.find(
          fee =>
            fee.regular_class_id === regular_class_id && fee.times === times
        );

        acc += tuition
          ? closed_flag
            ? tuition.closure_fee
            : tuition.tution_fee
          : 0;

        return acc;
      }, 0);
    },
    [tuitionFees.list]
  );

  const validateFormData = async () => {
    const getValidationRules = (formData, index) => {
      return [
        {
          key: "regular_class_id",
          test: () => {
            return {
              isValid: !regularClass.isUnassigned(formData.regular_class_id),
              message: `クラス${index + 1}の所属クラスを選択してください`,
            };
          },
        },
        {
          key: "times",
          test: () => {
            return {
              isValid: !!formData.times,
              message: `クラス${index + 1}の回数を選択してください`,
            };
          },
        },
        {
          key: "days",
          test: () => {
            let invalidPos = null;
            return {
              isValid:
                !regularClass.isDayTimeRequired(formData.regular_class_id) ||
                createArrayFromSize(formData.times).every(pos => {
                  const key = `day_week_${pos}`;
                  const isValid = !!formData[key];
                  if (!isValid) invalidPos = pos;
                  return isValid;
                }),
              message: `クラス${index + 1}の曜日${invalidPos}を選択してください`,
            };
          },
        },
        {
          key: "dayTime",
          test: () => {
            let invalidPos = null;
            return {
              isValid:
                !regularClass.isDayTimeRequired(formData.regular_class_id) ||
                createArrayFromSize(formData.times).every(pos => {
                  const key = `class_level_${pos}`;
                  const isValid = !!formData[key];
                  if (!isValid) invalidPos = pos;
                  return isValid;
                }),
              message: `クラス${index + 1}の時間${invalidPos}を選択してください`,
            };
          },
        },
        {
          key: "billingAmount",
          test: () => {
            return {
              isValid: billingAmount !== "",
              message: `請求額を入力してくださいください`,
            };
          },
        },
      ];
    };

    for (let [index, formData] of formList.entries()) {
      const validationRules = getValidationRules(formData, index);

      for (const rule of validationRules) {
        const testResult = rule.test(); // Call the test function
        if (!testResult.isValid) {
          setSelectedClassId(formData.id);
          await swalError(testResult.message); // Show swal error
          return false; // Stop validation and return false
        }
      }
    }

    return true;
  };

  const getStartDate = currentMonth => {
    if (!currentMonth) return null;
    let date = subtractMonths(currentMonth, 6);
    return dateFormat(date, 3);
  };

  const updateFormList = useCallback(
    async (formData, formList, type, selectedId = null) => {
      if (type === "create") {
        setFormList(formList);
        setBillingAmount(formList[0]?.billing_amount || getTotalFees(formList));
        setSelectedClassId(selectedId || formList[0]?.id || null);
        setDate(formList[0]?.processed_date);
      } else if (type.includes("add")) {
        setFormList(formList);
        setBillingAmount(getTotalFees(formList));
        setSelectedClassId(
          formList.at(type === "addFirst" ? 0 : -1).id || null
        );
        setDate(formList[0]?.processed_date);
        tableFilterButtonRef.current.click();
      } else if (type === "delete") {
        const newFormList = formList.filter(val => val.id !== formData.id);
        setFormList(newFormList);
        setBillingAmount(newFormList[0]?.billing_amount || getTotalFees(newFormList));
        setSelectedClassId(newFormList.at(-1)?.id || null);
        tableFilterButtonRef.current.click();
      } else if (type === "update") {
        const newFormList = formList.map(val =>
          val.id === formData.id ? formData : val
        );
        setFormList(newFormList);
        setBillingAmount(getTotalFees(newFormList));
        setSelectedClassId(formData.id);
        setDate(formData.processed_date);
      } else {
        return null;
      }
    },
    [getTotalFees]
  );

  const handleAddClass = async (member_id, date, tryCopy) => {
    const { unassignedClassId } = regularClass;

    const payload = {
      member_id,
      date,
      type: "create_new",
      unassigned_class_id: unassignedClassId,
      try_copy: tryCopy,
    };

    const callback = async (resType, data) => {
      if (resType === 1) {
        if (data && data.data && data.data.length > 0) {
          const newFormList = generateClassFormListInfo(data.data);
          updateFormList(null, newFormList, tryCopy ? "addFirst" : "addLast");
        }
      } else if (resType === 3) {
        await swalError(data.message); // messages.contactMessage
        return null;
      }
    };

    await postClasses(payload, callback);
  };

  useEffect(() => {
    if (currentMonth) {
      setDate(currentMonth);
    }
  }, [currentMonth]);

  const contextValues = {
    formList,
    setSelectedClassId,
    billingAmount,
    setBillingAmount,
    selectedClassId,
    totalFees: getTotalFees(formList),
    maxAllowedClasses,
    disableForm,
    handleAddClass,
    memberData,
    date,
    tableFilterButtonRef,
    currentMonth,
    classList,
    getStartDate,
    setClassList,
    updateFormList,
    readOnly,
    setReadOnly,
    formData,
    validateFormData,
  };

  return (
    <StudentClassContext.Provider value={contextValues}>
      {children}
    </StudentClassContext.Provider>
  );
};

export default StudentClassProvider;
