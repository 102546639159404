import moment from "moment";
import { payerInvoices } from "src/services/privateApi";
import { swalError, swalSuccess } from "src/utils/swalHelpers";
import Nvh from "../common/Nvh";
import messages from "src/utils/messages";

const PayerDelinquentCSVDownload = ({ date, filterStatus }) => {
  const fetchCSVData = async (processed_date, status) => {
    const callBack = async (resType, data) => {
      if (resType === 1) {
        return data.data;
      } else if (resType === 2) {
        await swalError(messages.payerDelinquentDataNotFound);
      } else if (resType === 3) {
        await swalError(messages.contactMessage);
      }
      return null;
    };

    const payload = {
      processed_date,
      status,
      page: 1,
      per_page: 999999999,
    };

    return payerInvoices(payload, callBack);
  };

  async function download_table_as_csv(
    table_id,
    classDataCSVExport,
    separator = ","
  ) {
    var csv = [];
    var header = [];
    header.push('"' + "会員番号" + '"');
    header.push('"' + "氏名" + '"');
    header.push('"' + "電話番号" + '"');
    header.push('"' + "銀行名" + '"');
    header.push('"' + "処理年月" + '"');
    header.push('"' + "請求額" + '"');
    header.push('"' + "振替請求額" + '"');
    header.push('"' + "振替入金額" + '"');
    header.push('"' + "振替入金日" + '"');
    header.push('"' + "現金入金額1" + '"');
    header.push('"' + "現金入金日1" + '"');
    header.push('"' + "現金入金額2" + '"');
    header.push('"' + "現金入金日2" + '"');
    header.push('"' + "過不足額" + '"');
    header.push('"' + "過不足額累計" + '"');
    header.push('"' + "備考" + '"');

    csv.push(header.join(separator));

    classDataCSVExport &&
      classDataCSVExport.forEach(data => {
        var row = [];
        row.push('"' + Nvh({ value: data?.member?.member_code }) + '"');
        row?.push('"' + Nvh({ value: data?.member?.name }) + '"');
        row?.push('"' + Nvh({ value: data?.member?.phone_no_1 }) + '"');
        row?.push('"' + Nvh({ value: data?.member?.bank_name }) + '"');
        row?.push('"' + Nvh({ value: data?.processed_date }) + '"');
        row?.push('"' + Nvh({ value: data?.billing_amount }) + '"');
        row?.push('"' + Nvh({ value: data?.transfer_invoice_amount }) + '"');
        row?.push('"' + Nvh({ value: data?.transfer_amount }) + '"');
        row?.push('"' + Nvh({ value: data?.transfer_deposit_date }) + '"');
        row?.push('"' + Nvh({ value: data?.cash_deposit_amount }) + '"');
        row?.push('"' + Nvh({ value: data?.cash_deposit_date }) + '"');
        row?.push('"' + Nvh({ value: data?.cash_deposit_amount2 }) + '"');
        row?.push('"' + Nvh({ value: data?.cash_deposit_date2 }) + '"');
        row?.push('"' + Nvh({ value: data?.excess_deficiency }) + '"');
        row?.push(
          '"' + Nvh({ value: data?.cumulative_excess_deficiency }) + '"'
        );
        row?.push('"' + Nvh({ value: data?.note }) + '"');

        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    // Download it
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swalSuccess("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async table_id => {
    try {
      const classDataCSVExport = await fetchCSVData(date, filterStatus);
      if (classDataCSVExport) {
        await download_table_as_csv(table_id, classDataCSVExport); // Pass the data to the download function
      }
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  return (
    <button
      type="button"
      onClick={() => {
        handleDownloadCSV("収納者_未納者リスト");
      }}
      className="btn btn-primary"
    >
      <i className="fas fa-download fa-fw"></i>
      CSV
    </button>
  );
};

export default PayerDelinquentCSVDownload;
