import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getToken } from "src/services/auth/token";
import privateApi from "../../services/privateApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";

import "../../assets/common/css/modal.css";
import swal from "sweetalert";

const configureAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
};

const formFieldArr = [
  {
    name: "name",
    type: "text",
    label: "氏名",
    placeholder: "例：ハンマ　バキ",
    required: true,
  },
  {
    name: "email",
    type: "email",
    label: "メールアドレス",
    placeholder: "⚪︎⚪︎⚪︎@gmail.com",
    required: false,
  },
  {
    name: "phone",
    type: "text",
    label: "電話番号",
    placeholder: "電話番号",
    required: false,
  },
  {
    name: "zipcode",
    type: "text",
    label: "郵便番号",
    placeholder: "",
    required: false,
  },
  {
    name: "address1",
    type: "text",
    label: "市区町村",
    placeholder: "ご入力ください",
    required: false,
  },
  {
    name: "address2",
    type: "text",
    label: "番地・物件名・部屋番号",
    placeholder: "ご入力ください",
    required: false,
  },
  {
    name: "login_id",
    type: "text",
    label: "ログインID",
    placeholder: "ご入力ください",
    required: true,
  },
  {
    name: "password_hash",
    type: "password",
    label: "パスワード",
    placeholder: "ご入力ください",
    required: true,
  },
];

const parentInit = {
  name: "",
  email: "",
  phone: "",
  zipcode: "",
  address1: "",
  address2: "",
  login_id: "",
  password_hash: "",
  parent_members: [],
};

const Parent = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const [parents, setParents] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const changTypeFn = (type, data = null) => {
    if (type === "add") {
      reset(parentInit);
      setSelectedOptions([]);
      setIsCreate(true);
    }

    if (type === "edit" && data) {
      reset(data);
      setSelectedOptions(data.parent_members);
      setIsCreate(false);
    }

    setShow(true);
  };

  const getParentData = async e => {
    const response = await privateApi.get("admin/parent");
    if (response.status === 200) {
      const updatedData = response.data.map(item => {
        const { members, ...rest } = item;
        return {
          ...rest,
          parent_members: members.map(member => ({
            value: member.id,
            label: member.name,
          })),
        };
      });

      setParents(updatedData);
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    getParentData();
    fetchOptions("");
  }, []);
  useEffect(() => {
    setValue("parent_members", selectedOptions);
  }, [selectedOptions]);

  const onSubmit = async data => {
    setIsLoading(true);
    configureAxios();

    if (isCreate) {
      try {
        await axios.post(`admin/parent`, data);
        setShow(false);
        swal("保護者を追加しました", "", "success");
      } catch (error) {
        setIsLoading(false);
        swal(
          "内容に問題があります",
          error.response?.data?.message || "エラーが発生しました",
          "error"
        );
      }
    } else {
      const { updated_at, created_at, id, ...rest } = data;
      try {
        await await axios.put(`admin/parent/${id}/`, rest);
        setShow(false);
        swal("保護者を追加しました", "", "success");
      } catch (error) {
        setIsLoading(false);
        swal(
          "内容に問題があります",
          error.response?.data?.message || "エラーが発生しました",
          "error"
        );
      }
    }
    setIsLoading(false);
    getParentData();
  };

  const onDelete = async () => {
    configureAxios();

    const response = await axios.post(`admin/parent-deleted/${selectedId}/`);
    if (response.status === 200) {
      setShowConfirm(false);
      swal("削除できました", "", "success");
    } else {
      swal("削除できませんでした", response.data.message, "error");
    }

    getParentData();
  };

  const fetchOptions = async (inputValue = "") => {
    setIsFetching(true);
    try {
      const response = await privateApi.get(
        `admin/parent-members-list?search=${inputValue}`
      );
      const data = response.data;

      const formattedOptions = data.map(item => ({
        label: item.name,
        value: item.id,
      }));

      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleChange = selected => {
    setSelectedOptions(selected);
  };
  const handleInputChange = inputValue => {
    fetchOptions(inputValue);
    return inputValue;
  };

  const formData = (
    <div>
      <h5 className="mb-4">保護者の基本情報</h5>
      <div className="row row-cols-1">
        {formFieldArr.map((field, index) => {
          if (!isCreate && field.name == "password_hash") {
            return;
          }
          return (
            <Form.Group
              key={index}
              className="col mb-3"
              controlId={field.name}
              style={field.name === "zipcode" ? { width: "250px" } : {}}
            >
              <Form.Label>
                {field.label}{" "}
                {field.required && <span className="required">*</span>}
              </Form.Label>
              <div className="d-flex align-items-center gap-2">
                <Form.Control
                  type={field.type}
                  placeholder={field.placeholder}
                  {...register(
                    field.name,
                    field.required ? { required: "この項目は必須です。" } : {}
                  )}
                />
                {/* {field.name === "zipcode" && (
                  <p
                    className="border p-1 text-nowrap mb-0"
                    style={{ cursor: "pointer" }}
                  >
                    自動入力
                  </p>
                )} */}
              </div>
              <Form.Control.Feedback type="invalid">
                {errors[field.name]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          );
        })}
        <Form.Group className="col mb-3" controlId="multiSelect">
          <Form.Label>保護者下会員</Form.Label>
          <div>
            <Select
              isMulti
              isLoading={isFetching}
              options={options}
              onInputChange={handleInputChange}
              onChange={handleChange}
              placeholder="Search..."
              value={selectedOptions}
              filterOption={null}
            />
            {selectedOptions?.length > 0 && (
              <ul className="pt-3">
                {selectedOptions?.map(option => (
                  <li key={option.value}>{option.label}</li>
                ))}
              </ul>
            )}
          </div>
        </Form.Group>
      </div>
    </div>
  );

  return (
    <>
      <div className="card px-2 py-3">
        <div className="row">
          <div className="col-10">
            <p className="headline">保護者一覧</p>
          </div>
          <div className="col-2 d-flex align-items-center">
            <button
              className="btn btn-sm btn-primary px-3 ml-auto"
              variant="primary"
              onClick={() => changTypeFn("add")}
            >
              {" "}
              <i className="fas fa-plus fa-fw"></i>
              追加
            </button>
          </div>
        </div>
      </div>

      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="text-center" style={{ width: "5%" }}>
                ID
              </th>
              <th className="text-center">名前</th>
              <th className="text-center">電話</th>
              <th className="text-center">メール</th>
              <th className="text-center">住所</th>
              <th className="text-center">操作</th>
            </tr>
          </thead>
          <tbody>
            {parents.map((parent, index) => (
              <tr key={index}>
                <td
                  onDoubleClick={() => changTypeFn("edit", parent)}
                  className="text-center"
                  style={{ width: "5%", cursor: "pointer" }}
                >
                  {index}
                </td>
                <td className="text-center">{parent?.name}</td>
                <td className="text-center">{parent?.phone}</td>
                <td className="text-center">{parent?.email}</td>
                <td className="text-center">
                  {parent?.address1} {parent?.address2}
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-sm btn-primary mx-1"
                    onClick={() => changTypeFn("edit", parent)}
                  >
                    <FontAwesomeIcon icon="edit" />
                  </button>
                  <button
                    className="btn btn-sm btn-danger mx-1"
                    onClick={() => {
                      setSelectedId(parent.id);
                      setShowConfirm(true);
                    }}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              保護者を追加
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              {formData}
              {isLoading ? (
                <Button
                  className="float-right mx-1 px-4"
                  variant="primary"
                  style={{ float: "right" }}
                  disabled={true}
                >
                  <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                </Button>
              ) : (
                <Button
                  className="float-right mx-1 px-4"
                  variant="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  保存
                </Button>
              )}
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>削除の確認</Modal.Title>
          </Modal.Header>
          <Modal.Body>消去してもよろしいですか？</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirm(false)}>
              キャンセル
            </Button>
            <Button variant="danger" onClick={onDelete}>
              消去
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Parent;
