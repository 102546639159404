import { useEffect, useRef, useState } from "react";
import RankPageFilter from "src/components/admin/rankManagement/RankPageFilter";
import useNav from "src/hooks/useNav";
import { dateFormat } from "src/utils/formatdate";
import moment from "moment";
import messages from "src/utils/messages";
import { swalError, swalSuccess, swalWarning } from "src/utils/swalHelpers";
import {
  fetchMemberClassesData,
  members as _members,
  memberClassUpdate,
  memberClassAssign,
} from "src/services/privateApi";
import StudentDetails from "src/components/admin/rankManagement/StudentDetails";
import { RedirectButton } from "src/components/common/Button/BackButton";
import ClassDetails from "src/components/admin/rankManagement/ClassDetails.js";
import { Button } from "react-bootstrap";
import deepCopy from "src/utils/deepCopy";
import { v4 as uuidv4 } from "uuid";
import generateClassPayload from "src/utils/generateClassPayload";
import useClassLevel from "src/hooks/useClassLevel";
import useRegularClassSchedule from "src/hooks/useRegularClassSchedule";

const initialMonthsInfo = ["前月", "当月", "翌月"].map((label, index) => {
  const addAmount = index - 1;
  let date = moment().add(addAmount, "months");
  date = dateFormat(date, 3);

  return { label, date, classes: [], isSelected: label === "当月" };
});

const RankHistoryBody = () => {
  const [monthsInfo, setMonthsInfo] = useState(initialMonthsInfo);
  const [memberData, setMemberData] = useState({});
  const [updatedClasses, setUpdatedClasses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const searchInputRef = useRef(null);
  const textInputRef = useRef(null);

  const { currentMonth } = useNav();
  const classLevel = useClassLevel();
  const regularClassSchedule = useRegularClassSchedule();

  useEffect(() => {
    const initializeMonthsInfo = () => {
      if (currentMonth && monthsInfo === initialMonthsInfo) {
        const newMonthsInfo = monthsInfo.map((month, index) => {
          const addAmount = index - 1;
          let date = moment(currentMonth).add(addAmount, "months");
          date = dateFormat(date, 3);
          return { ...month, date };
        });

        setMonthsInfo(newMonthsInfo);
      }
    };

    initializeMonthsInfo();
  }, [currentMonth, monthsInfo]);

  const mergeMonthInfoWithClassData = classData => {
    let tempClassData = deepCopy(classData);

    const classDetailInfo = monthsInfo.map((month, index, arr) => {
      // Get classes for the current month
      let monthClass = tempClassData.filter(
        data => data.processed_date === month.date
      );

      if (!monthClass.length && index > 0) {
        // Fallback to previous month's data if current month's data is missing
        const prevMonth = arr[index - 1];
        monthClass = tempClassData
          .filter(data => data?.processed_date === prevMonth?.date)
          .map(val => ({
            ...val,
            id: uuidv4(), // Assign a new unique ID
            processed_date: month.date, // Update to current month's date
          }));

        // Add the cloned data to tempClassData
        tempClassData = [...tempClassData, ...monthClass];
      }

      const classes = monthClass.filter(val => {
        return classLevel.isRankable(val.regular_class_id);
      });

      return {
        ...month,
        classes,
      };
    });

    return classDetailInfo;
  };

  const focusInput = () => {
    setTimeout(() => {
      searchInputRef.current.focus();
    });
  };

  const resetMonthsInfo = () => {
    const newMonthsInfo = monthsInfo.map(month => {
      return { ...month, classes: [] };
    });

    setMonthsInfo(newMonthsInfo);
  };

  const resetState = () => {
    setMemberData({});
    setUpdatedClasses([]);
    resetMonthsInfo();
  };

  // note praveen similar function is used in student class table
  const fetchMemberClassesDataWrapper = async (
    member_id,
    start_date,
    end_date
  ) => {
    const callBack = async (resType, data) => {
      if (resType === 1) {
        const classData = data.data;

        const newMonthsInfo = mergeMonthInfoWithClassData(classData);

        if (!newMonthsInfo.some(val => val.classes.length > 0)) {
          await swalError(messages.classDataNotFoundDate);
        }

        setMonthsInfo(newMonthsInfo);
        setTimeout(() => {
          textInputRef.current?.focus();
        }, 50);
        return;
      }

      const alertMessages = {
        2: messages.classDataNotFoundDate,
        3: data.message,
      };
      await swalError(alertMessages[resType]);
      focusInput();
    };

    const payload = { member_id, start_date, end_date, closest: 0 };

    await fetchMemberClassesData(payload, callBack);
  };

  const fetchMemberData = async memberCode => {
    resetState();
    if (memberCode) {
      const payload = { per_page: 10, search: memberCode.padStart(7, "0") };
      const { resType, data } = await _members(payload);

      if (resType === 1) {
        const memberData = data.data[0];
        setMemberData(memberData);
        await fetchMemberClassesDataWrapper(
          memberData.id,
          monthsInfo[0].date,
          monthsInfo[2].date
        );
      } else {
        if (resType === 2) await swalError(messages.memberNotFound);
        if (resType === 3) await swalError(data.message);

        focusInput();
      }
    } else {
      await swalWarning(messages.enterMemberCode);
      focusInput();
    }
  };

  const toggleSelectedMonth = monthLabel => {
    const newMonthsInfo = monthsInfo.map(val => {
      if (val.label === monthLabel) return { ...val, isSelected: true };
      return { ...val, isSelected: false };
    });
    setMonthsInfo(newMonthsInfo);
    setUpdatedClasses([]);
  };

  const selectedIndex = monthsInfo.findIndex(val => val.isSelected);

  const handleSave = async () => {
    setIsSubmitting(true);
    let shouldWarn = false;

    const payloads = monthsInfo
      .filter((_, index) => index <= selectedIndex)
      .map(val => val.classes)
      .flat()
      .filter(classInfo => {
        return Object.keys(classInfo).length > 2;
      })
      .map((detail, i) => {
        const classInfo =
          updatedClasses.find(val => val.id === detail.id) || detail;

        [1, 2, 3, 4, 5].forEach(num => {
          const start_time = classInfo["start_time_" + num];
          const end_time = classInfo["end_time_" + num];

          const classLevelInfo = classLevel?.list?.find(
            val =>
              val.from_level <= classInfo.class &&
              val.to_level >= classInfo.class &&
              val.regular_class_id === classInfo.regular_class_id
          );
          const regularClassScheduleInfo = regularClassSchedule.list
            ?.find(val => val.regular_class_id === classInfo.regular_class_id)
            ?.classSchedule?.find(
              val => val.day === classInfo["day_week_" + num]
            )
            ?.classes.find(
              val =>
                val.start_time === start_time &&
                val.end_time === end_time &&
                val.max_student === classLevelInfo.id
            );
          // const regularClass = regularClassSchedule.list.
          if (regularClassScheduleInfo) {
            classInfo["class_level_" + num] = regularClassScheduleInfo.id;
            classInfo["start_time_" + num] =
              regularClassScheduleInfo.start_time;
            classInfo["end_time_" + num] = regularClassScheduleInfo.end_time;
          } else if (start_time) {
            if (!shouldWarn) shouldWarn = true;
          }
        });

        const payload = generateClassPayload(classInfo, memberData.id);

        return payload;
      });

    const result = await Promise.all(
      payloads.map(payload => {
        // Decide the action based on the type of `classInfo.id`
        return typeof payload.id === "number"
          ? memberClassUpdate(payload, payload.id)
          : memberClassAssign(payload);
      })
    );

    const isSuccess = result.every(val => val.resType === 1);

    if (isSuccess) {
      !shouldWarn
        ? await swalSuccess(messages.classDataUpdate)
        : await swalWarning(
            messages.classDataUpdate + "! 合致する駒枠がありません"
          );

      const resultClassData = result.map(val => val.data.data).flat();
      const payloadIds = payloads.map(val => val.id);

      const classData = deepCopy(
        monthsInfo
          .map(val => val.classes)
          .flat()
          .map(cls => {
            const resultClassIndex = payloadIds.indexOf(cls.id);

            return resultClassIndex !== -1
              ? resultClassData[resultClassIndex]
              : cls;
          })
      );

      setMonthsInfo(mergeMonthInfoWithClassData(classData));
      focusInput();
    } else {
      const isTypeThree = result.some(val => val.resType === 3);
      if (isTypeThree) await swalError(messages.contactMessage);
    }

    setIsSubmitting(false);
  };

  const showClassDetails = () => {
    return monthsInfo?.some(month => month.classes.length > 0);
  };

  return (
    <div className="card rank-history">
      <div className="card-header d-flex justify-content-between">
        <button className="btn px-3 mr-4 btn-primary">進級処理</button>
        <RedirectButton
          redirectTo="/admin/dashboard"
          className="btn px-3 mr-4 btn-primary"
        >
          戻る
        </RedirectButton>
      </div>
      <div className="card-body">
        <RankPageFilter
          monthsInfo={monthsInfo}
          searchInputRef={searchInputRef}
          toggleSelectedMonth={toggleSelectedMonth}
          fetchMemberData={fetchMemberData}
        />
        <StudentDetails memberData={memberData} />
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSave();
          }}
        >
          {showClassDetails() && (
            <>
              {monthsInfo.map((month, index) => {
                if (index <= selectedIndex) {
                  return (
                    <ClassDetails
                      key={month.classes[0]?.id}
                      monthLabel={month.label}
                      isSelected={month.isSelected}
                      date={month.date}
                      classes={month.classes}
                      updatedClasses={updatedClasses}
                      setUpdatedClasses={setUpdatedClasses}
                      textInputRef={textInputRef}
                    />
                  );
                }

                return null;
              })}
              <Button
                className="float-right col-md-1 mt-2"
                variant="primary"
                type="submit"
                style={{ float: "right" }}
                disabled={isSubmitting}
              >
                保存
              </Button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default RankHistoryBody;
