import { useEffect, useState } from "react";
import CoachContext from "src/context/coachContext.js";
import { coaches } from "src/services/privateApi";
import messages from "src/utils/messages";
import { swalError } from "src/utils/swalHelpers";

const CoachProvider = ({ children, ...props }) => {
  const [list, setList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const callBack = (resType, data) => {
        if (resType === 1) {
          setList(data.data);
        } else {
          console.error("Error fetching data:", messages.contactMessage);
        }
      };

      await coaches(null, callBack);
      setIsLoaded(true);
    };

    fetchData();
  }, []);

  const getOptionList = () => {
    return [{ id: "no-value", name: "--" }, ...list].map(
      val => {
        return {
          ...val,
          value: val.id,
          label: val.name,
        };
      }
    );
  };

  const contextValues = { list, isLoaded, getOptionList };

  return (
    <CoachContext.Provider value={contextValues}>
      {children}
    </CoachContext.Provider>
  );
};

export default CoachProvider;
