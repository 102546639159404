import { Table as AntTable, ConfigProvider } from "antd";
import "react-resizable/css/styles.css";
import antdTheme from "src/themes/antdTheme";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import MyInput from "src/components/common/FormElements/MyInput";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import StudentClassTableCSVDownload from "./StudentClassTableCSVDownload";
import studentClassTableColumns from "./studentClassTableColumns";
import useStudentClass from "src/hooks/useStudentClass";
import generateClassFormListInfo from "src/utils/generateClassFormListInfo";
import useRegularClass from "src/hooks/useRegularClass";
import { fetchMemberClassesData } from "src/services/privateApi";
import messages from "src/utils/messages";
import { swalError } from "src/utils/swalHelpers";

// note praveen add date range validation in the future
const validationSchema = Yup.object({
  start_date: Yup.date().nullable(),
  end_date: Yup.date().nullable(),
});

const StudentClassTable = () => {
  const {
    selectedClassId,
    memberData,
    tableFilterButtonRef,
    currentMonth,
    classList,
    getStartDate,
    setClassList,
    updateFormList,
  } = useStudentClass();

  const regularClass = useRegularClass();

  const startDate = getStartDate(currentMonth);

  const handleRowClick = record => {
    const filterdClassList = classList.filter(
      val => val.processed_date === record.processed_date
    );
    const newFormList = generateClassFormListInfo(filterdClassList);
    updateFormList(null, newFormList, "create", record.id);
  };

  useEffect(() => {
    if (startDate && memberData.id) {
      const fetchData = async () => {
        const callBack = (resType, data) => {
          setClassList(resType === 1 ? data.data : []);
        };

        const payload = {
          member_id: memberData.id,
          start_date: startDate,
          end_date: null,
          closest: 0,
        };

        fetchMemberClassesData(payload, callBack);
      };

      fetchData();
    }
  }, [memberData.id, startDate, setClassList]);

  return (
    <div className="card mt-3">
      <div className="row m-0 card-header">
        <Formik
          initialValues={{
            start_date: startDate,
            end_date: null,
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={async ({ start_date, end_date }, { setSubmitting }) => {
            const fetchData = async (start_date, end_date) => {
              const callBack = (resType, data) => {
                if (resType === 1) {
                  setClassList(data.data);
                } else if (resType === 2 || resType === 3) {
                  setClassList([]);
                  swalError(
                    resType === 2
                      ? messages.classDataNotFoundDate
                      : data.message
                  );
                }
                setSubmitting(false);
              };

              const payload = {
                member_id: memberData.id,
                start_date,
                end_date,
                closest: 0,
              };

              fetchMemberClassesData(payload, callBack);
            };

            setSubmitting(true);
            fetchData(start_date, end_date);
          }}
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <div className="col-md-8">
                  <div className="row m-0 align-items-center">
                    <div
                      className="col"
                      style={{ width: "12%", flex: "0 0 auto" }}
                    >
                      処理年月:{" "}
                    </div>
                    <div className="col">
                      <MyInput
                        label=""
                        name="start_date"
                        type="month"
                        max="9999-12"
                        placeholder=""
                      ></MyInput>
                    </div>
                    <div
                      className="col"
                      style={{ width: "5%", flex: "0 0 auto" }}
                    >
                      <div className="text-center py-2">~</div>
                    </div>
                    <div className="col">
                      <MyInput
                        label=""
                        name="end_date"
                        type="month"
                        max="9999-12"
                        placeholder=""
                      ></MyInput>
                    </div>
                    <div
                      className="col"
                      style={{ width: "14%", flex: "0 0 auto" }}
                    >
                      <button
                        style={{ width: "100%" }}
                        className="btn btn-primary"
                        type="submit"
                        ref={tableFilterButtonRef}
                        disabled={formik.isSubmitting}
                      >
                        絞り込み
                      </button>
                    </div>
                    <div className="col">
                      <StudentClassTableCSVDownload />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className="table-responsive p-3">
        <ConfigProvider theme={antdTheme}>
          <AntTable
            bordered
            dataSource={classList}
            columns={studentClassTableColumns(regularClass.getAffiliationName)}
            rowClassName={({ id }) =>
              selectedClassId === id ? "table-selected-row" : ""
            }
            onRow={(record, rowIndex) => ({
              onClick: e => {
                handleRowClick(record);
              },
            })}
            className="text-left"
            pagination={false}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default StudentClassTable;
