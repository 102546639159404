import "react-resizable/css/styles.css";
import StudentClassBody from "./StudentClassBody";
import StudentClassTable from "./StudentClassTable";
import StudentClassProvider from "src/provider/studentClassProvider";
import RegularClassProvider from "src/provider/regularClassProvider";
import ClassLevelProvider from "src/provider/classLevelProvider";
import RegularClassScheduleProvider from "src/provider/regularClassScheduleProvider";
import TuitionFeesProvider from "src/provider/tuitionFeesProvider";

const StudentClass = ({
  memberData,
  readOnly,
  setReadOnly,
  setKey,
  children,
}) => {
  return (
    <div class="container-fluid">
      <TuitionFeesProvider>
        <RegularClassScheduleProvider>
          <RegularClassProvider>
            <ClassLevelProvider>
              <StudentClassProvider
                {...{
                  memberData,
                  readOnly,
                  setReadOnly,
                  isEdit: !readOnly,
                  setKey,
                }}
              >
                {children}
                <StudentClassBody />
                <StudentClassTable />
              </StudentClassProvider>
            </ClassLevelProvider>
          </RegularClassProvider>
        </RegularClassScheduleProvider>
      </TuitionFeesProvider>
    </div>
  );
};

export default StudentClass;
