import TimePicker from "react-time-picker";

const TimePickerField = ({ name, value, onChange }) => {
  return (
    <TimePicker
      className="bg-white"
      name={name}
      value={value}
      onChange={onChange}
      clearIcon={null}
      format="HH:mm"
      disableClock
    />
  );
};

export default TimePickerField;
