import { useEffect, useState } from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import MyInput from "src/components/common/FormElements/MyInput";
import _, { update } from "lodash";
import StudentClassForm from "src/components/extrapage/Page19/StudentClassForm.js";
import useStudentClass from "src/hooks/useStudentClass";
import generateClassFormListInfo from "src/utils/generateClassFormListInfo";
import { fetchMemberClassesData } from "src/services/privateApi";
import { swalError } from "src/utils/swalHelpers";
import messages from "src/utils/messages";

const StudentClassBody = () => {
  const {
    currentMonth,
    date,
    memberData,
    readOnly,
    updateFormList,
    handleAddClass,
  } = useStudentClass();

  useEffect(() => {
    if (memberData.id && currentMonth) {
      const fetchData = async () => {
        const callBack = (resType, data) => {
          if (resType === 1) {
            const newFormList = generateClassFormListInfo(data.data);
            updateFormList(null, newFormList, "create");
          }
        };

        const payload = {
          member_id: memberData.id,
          start_date: currentMonth,
          end_date: currentMonth,
          closest: 0,
        };

        await fetchMemberClassesData(payload, callBack);
      };

      fetchData();
    }
  }, [currentMonth, memberData.id, updateFormList]);

  return (
    <div className="row m-0 ">
      <div className="card pb-1 pt-2">
        <div className="row m-0 mt-2 my-2">
          <Formik
            initialValues={{ date }}
            enableReinitialize={true}
            onSubmit={async ({ date }, { setSubmitting }) => {
              setSubmitting(true);
              const fetchData = async () => {
                const callBack = async (resType, data) => {
                  if (resType === 1) {
                    const newFormList = generateClassFormListInfo(data.data);
                    updateFormList(null, newFormList, "create");
                  } else if (resType === 2) {
                    if (!readOnly) {
                      await handleAddClass(memberData.id, date, true);
                    } else {
                      await swalError(messages.classDataNotFoundDateRead);
                      const newFormList = generateClassFormListInfo(null, date);
                      updateFormList(null, newFormList, "create");
                    }
                  } else if (resType === 3) {
                    await swalError(data.message);
                  }

                  setSubmitting(false);
                };

                const payload = {
                  member_id: memberData.id,
                  start_date: date,
                  end_date: date,
                  closest: 0,
                };

                fetchMemberClassesData(payload, callBack);
              };

              fetchData();
            }}
          >
            {formik => {
              const buttonProps = !readOnly
                ? { children: "検索/追加" }
                : { children: "検索" };

              const disabled =
                _.isEmpty(memberData) || !currentMonth || formik.isSubmitting;

              return (
                <div className="row col-md-5">
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-3 text-md-end pt-1">年月:</div>
                      <div className="col-4">
                        <MyInput
                          label=""
                          name="date"
                          type="month"
                          max="9999-12"
                          placeholder=""
                          disabled={disabled}
                        />
                      </div>
                      <div className="col-md-3">
                        <button
                          className="btn btn-primary w-100"
                          type="submit"
                          disabled={disabled}
                          {...buttonProps}
                        ></button>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
        <StudentClassForm />
      </div>
    </div>
  );
};

export default StudentClassBody;
