import { useEffect, useState } from "react";
import ClassLevelContext from "src/context/classLevelContext.js";
import { classLevels } from "src/services/privateApi";
import messages from "src/utils/messages";
import { swalError } from "src/utils/swalHelpers";

const ClassLevelProvider = ({ children, ...props }) => {
  const [list, setList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch class levels
        const classLevelsResponse = await classLevels();
        if (classLevelsResponse.resType === 1) {
          setList(classLevelsResponse.data.data);
        } else if (classLevelsResponse.resType === 3) {
          await swalError(classLevelsResponse.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        await swalError(messages.contactMessage);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchData();
  }, []);

  const rankClassIdList = list
    .map(val => {
      return val.regular_class_id;
    })
    .filter((val, index, arr) => {
      return index === arr.indexOf(val);
    });

  const isRankable = regularClassId => {
    // return rankClassIdList.includes(parseInt(regularClassId));
    return true; // make all classes rankable
  };

  const getOptionList = regularClassId => {
    return [
      { id: "no-value", in_text: "--" },
      ...list.filter(val => val.regular_class_id === regularClassId),
    ].map(val => {
      return {
        ...val,
        value: val.id,
        label: val.in_text,
      };
    });
  };

  const contextValues = { list, isLoaded, isRankable, getOptionList };

  return (
    <ClassLevelContext.Provider value={contextValues}>
      {children}
    </ClassLevelContext.Provider>
  );
};

export default ClassLevelProvider;
