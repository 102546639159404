import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { swalWarning } from "src/utils/swalHelpers";
import messages from "src/utils/messages";
import TextLabel from "./TextLabel";
import useStudentClass from "src/hooks/useStudentClass";
import useRegularClass from "src/hooks/useRegularClass";

function StudentClassFormTabs({ disableButtons }) {
  const {
    formList,
    setSelectedClassId,
    billingAmount,
    setBillingAmount,
    selectedClassId,
    totalFees,
    maxAllowedClasses,
    disableForm,
    handleAddClass,
    memberData,
    date,
  } = useStudentClass();

  const regularClass = useRegularClass();

  const handleClassToggle = async id => {
    setSelectedClassId(id);
  };

  const handleAdd = async () => {
    const regular_class_id = formList[formList.length - 1].regular_class_id;
    if (!regularClass.isUnassigned(regular_class_id)) {
      handleAddClass(memberData.id, date, false);
    } else {
      swalWarning(messages.addClassWarning);
    }
  };

  // Render buttons for class data
  const renderClassButtons = () => {
    return formList.map(item => {
      const buttonClassName =
        "mb-0 border btn shadow-md" +
        (selectedClassId === item?.id ? " btn-secondary" : "");

      return (
        <button
          key={item.id}
          className={buttonClassName}
          type="button"
          onClick={() => handleClassToggle(item.id)}
          disabled={!formList[0]?.id}
        >
          {regularClass.getAffiliationName(item?.regular_class_id) || "--"}
        </button>
      );
    });
  };

  const handleBillingAmountUpdate = e => {
    setBillingAmount(e.target.value);
  };

  return (
    <Formik
      initialValues={{ fees_total: 0, billing_amount: 0 }}
      enableReinitialize={true}
    >
      {formik => (
        <Form>
          <div className="row m-0 mt-2 justify-content-between student-class-form-tabs">
            <div className="col-md-12">
              <div className="row m-0 g-1 align-items-center">
                {/* Class Tabs */}
                <div className="col-md-2 class-tabs">
                  {renderClassButtons()}
                  {formList.length < maxAllowedClasses && (
                    <button
                      className="mb-0 border btn shadow-md"
                      type="button"
                      onClick={handleAdd}
                      disabled={disableButtons}
                    >
                      +
                    </button>
                  )}
                </div>

                {/* Spacer */}
                <div className="col-md-7"></div>

                {/* Fees and Billing */}
                <div className="col-md-1 text-end">授業料合計:</div>
                <div className="col-md-1 px-2">
                  <TextLabel label={totalFees} />
                </div>
                <div className="col-md-1 px-2">
                  <input
                    className="form-control"
                    value={billingAmount}
                    onChange={handleBillingAmountUpdate}
                    disabled={disableForm}
                  />
                  <div className="feeback px-1 invalid-feedback"></div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default StudentClassFormTabs;
