const messages = {
  contactMessage:
    "エラーが発生しました。もう一度お試しください。この問題が続く場合は、大変お手数ですがご連絡ください",
  enterMemberCode: "会員番号を入力してください",
  memberNotFound: "入力した会員番号に該当するメンバーがありません",
  classDataNotFound: "選択した生徒は該当するクラスデータがありません",
  classDataNotFoundDate: "選択した月日は該当するクラスデータがありません",
  classDataNotFoundDateRead:
    "クラスデータがありません、編集モードから追加してください",
  classDataUpdateConfirm: "クラスデータを保存しましか？",
  timeTableSaveConfirm: "タイムテーブルデータを保存しました",
  timeTableSaveError: "タイムテーブルデータを保存できませんでした",
  addNewClassData:
    "選択した月日には該当するクラスデータがありません、データ入力してクラスを追加してください",
  classDataUpdate: "クラスデータを保存しました",
  classDataDeleteConfirm: "クラスデータを削除しますか？",
  classDataDelete: "クラスデータを削除しました",
  regularClassDataNotFound: "クラスマスターが空っぽです、追加してください",
  classScheduleNotFound: "タイムテーブルが空っぽです、追加してください",
  tuitionInfoNotFound: "授業料マスターが空っぽです、追加してください",
  addClassWarning: "左からクラスを入力してください",
  payerDelinquentDataNotFound: "選択した月日には収納データがありません",
  selectClass: "クラスを選択してください",
};

export default messages;
