import { daysOfWeek } from "./constants";

export const normalSort = (a, b) => {
  if ((a || a === 0) && (b || b === 0)) {
    return a - b;
  }
  if (a) return -1;
  if (b) return 1;
  return 0;
};

export const dateSort = (a, b) => {
  if (a && b) return new Date(a) - new Date(b);
  if (a) return -1;
  if (b) return 1;
  return 0;
};

export const stringSort = (a, b) => {
  if (typeof a === "string" && typeof b === "string") return a.localeCompare(b);
  if (typeof a === "string") return -1;
  if (typeof b === "string") return 1;
  return 0;
};

export const booleanSort = (a, b) => {
  if (typeof a === "boolean" && typeof b === "boolean") return b - a;
  if (typeof a === "boolean") return -1;
  if (typeof b === "boolean") return 1;
  return 0;
};

export const weekDaySort = (a, b) => {
  if (typeof a === "string" && typeof b === "string") {
    const bIndex = daysOfWeek.indexOf(b);
    const aIndex = daysOfWeek.indexOf(a);
    return bIndex - aIndex;
  }
  if (typeof a === "string") return -1;
  if (typeof b === "string") return 1;
  return 0;
};
