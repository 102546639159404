import { v4 as uuidv4 } from "uuid";

export const daysOfWeek = ["月", "火", "水", "木", "金", "土", "日"];

export const dummySchedule = () => {
  return {
    coach_id: "",
    end_time: "",
    id: uuidv4(),
    max_student: "",
    start_time: "",
  };
};

export const maxAllowedClasses = 3;
