import { useEffect, useState } from "react";
import RegularClassContext from "src/context/regularClassContext.js";
import { regularClasses } from "src/services/privateApi";
import messages from "src/utils/messages";
import { swalError } from "src/utils/swalHelpers";

const RegularClassProvider = ({ children, ...props }) => {
  const [list, setList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch regular classes
        const regularClassesResponse = await regularClasses();
        if (regularClassesResponse.resType === 1) {
          const regularClassData = regularClassesResponse.data.data;
          setList(regularClassData);
        } else if (regularClassesResponse.resType === 2) {
          await swalError(messages.regularClassDataNotFound);
        } else if (regularClassesResponse.resType === 3) {
          await swalError(regularClassesResponse.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        await swalError(messages.contactMessage);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchData();
  }, []);

  const getClassData = regularClassId => {
    return (
      list.find(val => {
        return val.id === regularClassId;
      }) || null
    );
  };

  const getAffiliationName = regularClassId => {
    return list.find(val => val.id === regularClassId)?.affiliation_name || "";
  };

  const isDayTimeRequired = regularClassId => {
    return getClassData(regularClassId)?.day_selection_flag;
  };

  const unassignedClass = list.find(val => val.affiliation_name === "未定");

  const unassignedClassId = unassignedClass?.id;

  const isUnassigned = regularClassId => {
    return regularClassId === unassignedClassId;
  };

  const contextValues = {
    list,
    isLoaded,
    unassignedClassId,
    getAffiliationName,
    isUnassigned,
    isDayTimeRequired,
  };

  return (
    <RegularClassContext.Provider value={contextValues}>
      {children}
    </RegularClassContext.Provider>
  );
};

export default RegularClassProvider;
