import ClassLevelProvider from "src/provider/classLevelProvider";
import RegularClassProvider from "src/provider/regularClassProvider";
import RankHistoryBody from "./RankHistoryBody";
import RegularClassScheduleProvider from "src/provider/regularClassScheduleProvider";

const RankHistory = () => {
  return (
    <RegularClassScheduleProvider>
      <RegularClassProvider>
        <ClassLevelProvider>
          <RankHistoryBody />
        </ClassLevelProvider>
      </RegularClassProvider>
    </RegularClassScheduleProvider>
  );
};

export default RankHistory;
