const generateClassPayload = (classInfo, memberId) => {
  const payload = {
    weekdays: [],
    class_times: [],
    buses: [],
    member_id: memberId,
    month: classInfo.processed_date,
    class_id: classInfo.regular_class_id,
    times: classInfo.times,
    class_level: classInfo.class,
    billing_amount: classInfo.billing_amount,
    closed_flag: classInfo.closed_flag,
    id: classInfo.id,
  };

  [1, 2, 3, 4, 5].forEach(val => {
    payload.weekdays.push(classInfo[`day_week_${val}`] || "");
    payload.buses.push(classInfo[`bus_flag_${val}`]);
    payload.class_times.push({
      start_time: classInfo[`start_time_${val}`] || "",
      end_time: classInfo[`end_time_${val}`] || "",
      class_level: classInfo[`class_level_${val}`] || "",
    });
  });

  return payload;
};

export default generateClassPayload;
