import moment from "moment";
import Nvh from "src/components/common/Nvh";
import useRegularClass from "src/hooks/useRegularClass";
import useStudentClass from "src/hooks/useStudentClass";
import swal from "sweetalert";

// not praveen, while not high priority theres scope to refactor this component
const StudentClassTableCSVDownload = () => {
  const { memberData, classList } = useStudentClass();
  const regularClass = useRegularClass();

  async function download_table_as_csv(separator = ",") {
    var csv = [];
    var header = [];
    header.push('"' + "会員番号" + '"');
    header.push('"' + "氏名" + '"');
    header.push('"' + "処理年月" + '"');
    header.push('"' + "回数" + '"');
    header.push('"' + "所属" + '"');
    header.push('"' + "級" + '"');
    header.push('"' + "休校" + '"');
    header.push('"' + "曜日1" + '"');
    header.push('"' + "時間1" + '"');
    header.push('"' + "バス1" + '"');
    header.push('"' + "曜日2" + '"');
    header.push('"' + "時間2" + '"');
    header.push('"' + "バス2" + '"');
    header.push('"' + "曜日3" + '"');
    header.push('"' + "時間3" + '"');
    header.push('"' + "バス3" + '"');
    header.push('"' + "曜日4" + '"');
    header.push('"' + "時間4" + '"');
    header.push('"' + "バス4" + '"');
    header.push('"' + "曜日5" + '"');
    header.push('"' + "時間5" + '"');
    header.push('"' + "バス5" + '"');

    csv.push(header.join(separator));
    classList &&
      classList.forEach(data => {
        var row = [];
        const affiliationName = regularClass.getAffiliationName(
          data.regular_class_id
        );

        row.push('"' + Nvh({ value: memberData.member_code }) + '"');
        row.push('"' + Nvh({ value: memberData.name }) + '"');
        row.push('"' + Nvh({ value: data.processed_date }) + '"');
        row.push('"' + Nvh({ value: data.times }) + '"');
        row.push('"' + affiliationName + '"');
        row.push('"' + Nvh({ value: data.class }) + '"');
        row.push('"' + Nvh({ value: data.closed_flag }) + '"');
        row.push('"' + Nvh({ value: data.day_week_1 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_1 }) + '"');
        if (data.bus_flag_1 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_1 }) + '"');
        } else {
          if (data.day_week_1 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_1 }) + '"');
          } else {
            row.push('""');
          }
        }
        row.push('"' + Nvh({ value: data.day_week_2 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_2 }) + '"');
        if (data.bus_flag_2 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_2 }) + '"');
        } else {
          if (data.day_week_2 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_2 }) + '"');
          } else {
            row.push('""');
          }
        }

        row.push('"' + Nvh({ value: data.day_week_3 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_3 }) + '"');
        if (data.bus_flag_3 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_3 }) + '"');
        } else {
          if (data.day_week_3 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_3 }) + '"');
          } else {
            row.push('""');
          }
        }
        row.push('"' + Nvh({ value: data.day_week_4 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_4 }) + '"');
        if (data.bus_flag_4 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_4 }) + '"');
        } else {
          if (data.day_week_4 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_4 }) + '"');
          } else {
            row.push('""');
          }
        }
        row.push('"' + Nvh({ value: data.day_week_5 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_5 }) + '"');
        if (data.bus_flag_5 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_5 }) + '"');
        } else {
          if (data.day_week_5 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_5 }) + '"');
          } else {
            row.push('""');
          }
        }
        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    // Download it
    var filename =
      "クラス_" +
      memberData.member_code +
      "_" +
      memberData.furigana +
      "_" +
      moment().format("YYYYMMDD") +
      ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async () => {
    try {
      await download_table_as_csv();
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  return (
    <button
      className="btn btn-primary w-100"
      onClick={() => {
        handleDownloadCSV();
      }}
    >
      <i className="fas fa-download fa-fw"></i>
      CSV
    </button>
  );
};

export default StudentClassTableCSVDownload;
