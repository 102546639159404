import { useState, useEffect, useRef } from "react";

import PayerDelinquentCSVDownload from "./PayerDelinquentCSVDownload";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import MyInput from "../common/FormElements/MyInput";
import useNav from "src/hooks/useNav";
import { useCallback } from "react";
import messages from "src/utils/messages";
import { swalError } from "src/utils/swalHelpers";
import { payerInvoices } from "src/services/privateApi";
import { ConfigProvider, Table } from "antd";
import antdTheme from "src/themes/antdTheme";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { stringSort, weekDaySort } from "src/utils/sorters";
import Nvh from "../common/Nvh";
import useRegularClass from "src/hooks/useRegularClass";

const Payer = () => {
  const history = useHistory();

  const { currentMonth } = useNav();

  const [payerList, setPayerList] = useState([]);
  const [date, setDate] = useState("");
  const [filterStatus, setFilterStatus] = useState(null);
  const [pageMeta, setPageMeta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const { getAffiliationName } = useRegularClass();

  const isFetching = useRef(false);
  const formikRef = useRef(null);

  const fetchPayerInvoices = useCallback(
    async (processed_date, status, page, payerList, calledFrom) => {
      setIsLoading(true);

      const updateState = data => {
        setPayerList([...payerList, ...data.data]);
        setPageMeta(data.meta);
      };

      const resetState = () => {
        setPayerList([]);
        setPageMeta(null);
      };

      const callBacks = {
        // scroll
        effect: (resType, data) => {
          if (resType === 1) {
            updateState(data);
          }
        },
        handler: async (resType, data) => {
          if (resType === 1) {
            updateState(data);
          } else if (resType === 2) {
            await swalError(messages.payerDelinquentDataNotFound);
            resetState();
          } else if (resType === 3) {
            await swalError(messages.contactMessage);
            resetState();
          }
        },
      };

      const payload = {
        processed_date,
        status,
        page,
        per_page: 100,
      };

      await payerInvoices(payload, callBacks[calledFrom]);
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      await fetchPayerInvoices(currentMonth, null, 1, payerList, "effect");
      setIsInitialDataLoaded(true);
    };

    if (currentMonth && !isInitialDataLoaded) {
      setDate(currentMonth);
      fetchData();
    }
  }, [currentMonth, payerList, isInitialDataLoaded, fetchPayerInvoices]);

  const handleScroll = useCallback(
    async e => {
      if (isFetching.current || !pageMeta) return;

      const page = pageMeta?.current_page + 1;

      if (page > pageMeta?.last_page) return;

      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
      if (distanceFromBottom <= 100) {
        isFetching.current = true;

        await fetchPayerInvoices(date, filterStatus, page, payerList, "effect");
        isFetching.current = false;
      }
    },
    [pageMeta, date, filterStatus, payerList, fetchPayerInvoices]
  );

  useEffect(() => {
    const scrollContainer = document.querySelector(".ant-table-body");

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const columns = [
    {
      title: "会員番号",
      dataIndex: "member?.member_code",
      key: "member?.member_code",
      width: 110,
      sorter: (a, b) => {
        return stringSort(a?.member?.member_code, b?.member?.member_code);
      },
      render: (text, record) => <span>{record?.member?.member_code}</span>,
    },
    {
      title: "氏名",
      dataIndex: "member?.name",
      key: "member?.name",
      width: 120,
      sorter: (a, b) => {
        return stringSort(a?.member?.name, b?.member?.name);
      },
      render: (text, record) => <span>{record?.member?.name}</span>,
    },
    {
      title: "クラス",
      dataIndex: "class_affiliation",
      key: "class_affiliation",
      width: 80,
      sorter: (a, b) => {
        return stringSort(a?.class_affiliation, b?.class_affiliation);
      },
      render: (text, record) => <span>{record?.class_affiliation}</span>,
    },
    {
      title: "曜日",
      dataIndex: "class_schedules",
      key: "class_schedules",
      width: 200,
      sorter: (a, b) => {
        return weekDaySort(
          a?.class_schedules?.slice(0, 1),
          b?.class_schedules?.slice(0, 1)
        );
      },
      render: (text, record) => <span>{record?.class_schedules}</span>,
    },
    {
      title: "級",
      dataIndex: "class_rank",
      key: "class_rank",
      width: 60,
      sorter: (a, b) => {
        return stringSort(a?.class_rank, b?.class_rank);
      },
      render: (text, record) => <span>{record?.class_rank}</span>,
    },
    {
      title: "電話番号",
      dataIndex: "member?.phone_no_1",
      key: "member?.phone_no_1",
      width: 130,
      sorter: (a, b) => {
        return stringSort(a?.member?.phone_no_1, b?.member?.phone_no_1);
      },
      render: (text, record) => <span>{record?.member?.phone_no_1}</span>,
    },
    {
      title: "銀行名",
      dataIndex: "member?.bank_name",
      key: "member?.bank_name",
      width: 120,
      sorter: (a, b) => {
        return stringSort(a?.member?.bank_name, b?.member?.bank_name);
      },
      render: (text, record) => <span>{record?.member?.bank_name}</span>,
    },
    {
      title: "処理年月",
      dataIndex: "processed_date",
      key: "processed_date",
      width: 90,
      sorter: (a, b) => {
        const aTimes = String(a?.processed_date);
        const bTimes = String(b?.processed_date);
        return aTimes?.localeCompare(bTimes);
      },
      render: (text, record) => <span>{record?.processed_date}</span>,
    },
    {
      title: "請求額",
      dataIndex: "billing_amount",
      key: "billing_amount",
      width: 80,
      render: (text, record) => (
        <span>{Nvh({ value: record?.billing_amount })}</span>
      ),
      sorter: (a, b) => {
        const aTimes = String(a?.billing_amount);
        const bTimes = String(b?.billing_amount);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "振替請求額",
      dataIndex: "transfer_invoice_amount",
      key: "transfer_invoice_amount",
      width: 110,

      render: (text, record) => <span>{record?.transfer_invoice_amount}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.transfer_invoice_amount);
        const bTimes = String(b?.transfer_invoice_amount);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "振替入金額",
      dataIndex: "transfer_amount",
      key: "transfer_amount",
      width: 110,
      render: (text, record) => (
        <span>{Nvh({ value: record?.transfer_amount })}</span>
      ),
      sorter: (a, b) => {
        const aTimes = String(a?.transfer_amount);
        const bTimes = String(b?.transfer_amount);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "振替入金日",
      dataIndex: "transfer_deposit_date",
      key: "transfer_deposit_date",
      width: 110,
      render: (text, record) => <span>{record?.transfer_deposit_date}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.transfer_deposit_date);
        const bTimes = String(b?.transfer_deposit_date);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "現金入金額1",
      dataIndex: "cash_deposit_amount",
      key: "cash_deposit_amount",
      width: 115,
      render: (text, record) => <span>{record?.cash_deposit_amount}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.cash_deposit_amount);
        const bTimes = String(b?.cash_deposit_amount);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "現金入金日1",
      dataIndex: "cash_deposit_date",
      key: "cash_deposit_date",
      width: 115,
      render: (text, record) => <span>{record?.cash_deposit_date}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.cash_deposit_date);
        const bTimes = String(b?.cash_deposit_date);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "現金入金額2",
      dataIndex: "cash_deposit_amount2",
      key: "cash_deposit_amount2",
      width: 130,
      render: (text, record) => <span>{record?.cash_deposit_amount2}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.cash_deposit_amount2);
        const bTimes = String(b?.cash_deposit_amount2);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "現金入金日2",
      dataIndex: "cash_deposit_date2",
      key: "cash_deposit_date2",
      width: 130,
      render: (text, record) => <span>{record?.cash_deposit_date2}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.cash_deposit_date2);
        const bTimes = String(b?.cash_deposit_date2);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "過不足額",
      dataIndex: "excess_deficiency",
      key: "excess_deficiency",
      width: 100,
      render: (text, record) => <span>{record?.excess_deficiency}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.excess_deficiency);
        const bTimes = String(b?.excess_deficiency);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "過不足額累計",
      dataIndex: "cumulative_excess_deficiency",
      key: "cumulative_excess_deficiency",
      width: 130,
      render: (text, record) => (
        <span>{record?.cumulative_excess_deficiency}</span>
      ),
      sorter: (a, b) => {
        const aTimes = String(a?.cumulative_excess_deficiency);
        const bTimes = String(b?.cumulative_excess_deficiency);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "備考",
      dataIndex: "note",
      key: "note",
      width: 150,
      render: (text, record) => <span>{Nvh({ value: record?.note })}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.note);
        const bTimes = String(b?.note);
        return aTimes?.localeCompare(bTimes);
      },
    },
  ];

  const handleFilterToggle = async e => {
    let newFilterStatus = e.target.name;
    newFilterStatus = filterStatus === newFilterStatus ? null : newFilterStatus;
    setFilterStatus(newFilterStatus);
    await fetchPayerInvoices(date, newFilterStatus, 1, [], "effect");
  };

  const handleRowClick = record => {
    const newSelectedRowId = selectedRowId === record?.id ? null : record?.id;
    setSelectedRowId(newSelectedRowId);
  };

  const handleRowDoubleClick = record => {
    history.push(
      `/admin/student/details/${record?.member?.id}?action=view&key=tab1`
    );
  };

  const rowClassName = record => {
    return selectedRowId === record?.id ? "table-selected-row" : "";
  };

  const getDataSource = payerList => {
    const dayTimeValue = classInfo => {
      return [1, 2, 3, 4, 5]
        .map(i => {
          const dayWeek = classInfo?.[`day_week_${i}`];
          const startTime = classInfo?.[`start_time_${i}`];
          const endTime = classInfo?.[`end_time_${i}`];

          if (dayWeek) {
            const day = Nvh({ value: dayWeek });
            const start = Nvh({ value: startTime });
            const end = Nvh({ value: endTime });
            return `${day}(${start}-${end})`;
          }

          return "";
        })
        .filter(Boolean)
        .join(" ");
    };

    return payerList.map(val => {
      const class_affiliation = getAffiliationName(
        val?.classes[0]?.regular_class_id
      );
      const class_rank = val?.classes[0]?.class;
      const class_schedules = dayTimeValue(val?.classes[0]);

      return { ...val, class_affiliation, class_rank, class_schedules };
    });
  };

  return (
    <div>
      <div>
        <p className="headline">収納者／未納者リスト</p>
      </div>
      <div>
        <div className="d-flex justify-content-left border p-2 mb-2">
          <div className="ml-10">
            <button
              className={`btn w-100 ${filterStatus === "paid" ? "btn-success" : "btn-primary"}`}
              name="paid"
              onClick={handleFilterToggle}
              type="button"
            >
              収納者
            </button>
          </div>
          <div className="ml-10">
            <button
              className={`btn w-100 ${filterStatus === "unpaid" ? "btn-success" : "btn-primary"}`}
              name="unpaid"
              onClick={handleFilterToggle}
              type="button"
            >
              未納者
            </button>
          </div>
          <Formik
            innerRef={formikRef}
            initialValues={{ date }}
            enableReinitialize={true}
            onSubmit={async ({ date }, { setSubmitting }) => {
              setSubmitting(true);
              await fetchPayerInvoices(date, filterStatus, 1, [], "handler");
              setSubmitting(false);
            }}
          >
            {formik => {
              return (
                <Form onSubmit={formik.handleSubmit}>
                  <div className="d-flex">
                    <div className="text-md-end align-self-center ml-10">
                      年月:
                    </div>
                    <div className="ml-10">
                      <MyInput
                        label=""
                        name="date"
                        type="month"
                        max="9999-12"
                        placeholder=""
                      />
                    </div>
                    <div className="ml-10">
                      <button
                        className="btn btn-primary w-100"
                        onSubmit={formik.handleSubmit}
                        type="submit"
                      >
                        検索
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div className="col-md-2 ml-10">
            <PayerDelinquentCSVDownload
              date={date}
              filterStatus={filterStatus}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="card-body" style={{ padding: "inherit" }}>
          <div>
            <div className="table-container2 member-table">
              <ConfigProvider theme={antdTheme}>
                <Table
                  dataSource={getDataSource(payerList)}
                  columns={columns}
                  scroll={{ y: "65vh" }}
                  loading={isLoading}
                  pagination={false}
                  rowKey="id"
                  rowClassName={rowClassName}
                  onRow={record => ({
                    onClick: () => handleRowClick(record),
                    onDoubleClick: () => handleRowDoubleClick(record),
                  })}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payer;
