import { useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { postClassSchedules } from "../../services/privateApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RedirectButton } from "../common/Button/BackButton";
import useRegularClass from "src/hooks/useRegularClass";
import useClassLevel from "src/hooks/useClassLevel";
import MyBsSelect from "../common/FormElements/MyBsSelect";
import { daysOfWeek, dummySchedule } from "src/utils/constants";
import generateOptions from "src/utils/generateOptions";
import useRegularClassSchedule from "src/hooks/useRegularClassSchedule";
import useCoach from "src/hooks/useCoach";
import deepCopy from "src/utils/deepCopy";
import { swalError, swalSuccess, swalWarning } from "src/utils/swalHelpers";
import messages from "src/utils/messages";
import TimePickerField from "../common/TimePickerField";

const CourseTime = ({ history }) => {
  const regularClass = useRegularClass();
  const classLevel = useClassLevel();
  const regularClassSchedule = useRegularClassSchedule();
  const coach = useCoach();

  const [selectedClassId, setSelectedClassId] = useState("no-value");
  const [tableData, setTableData] = useState(
    regularClassSchedule.generateScheduleTableData(null)
  );

  const tableBodyRef = useRef();

  const handleAffiliationChange = e => {
    const { value } = e.target;
    setSelectedClassId(value === "no-value" ? value : parseInt(value));
    const tableData = regularClassSchedule.generateScheduleTableData(
      value === "no-value" ? null : parseInt(value)
    );
    setTableData(tableData);
  };

  const handleChange = (value, name, slot, index) => {
    const newTableData = deepCopy(tableData);
    newTableData[slot - 1].schedules[index][name] = value;
    setTableData(newTableData);
  };

  const handleSelectChange = (e, slot, index) => {
    let { value, name } = e.target;
    value = value === "no-value" ? null : parseInt(value);
    handleChange(value, name, slot, index);
  };

  const handleTimeChange = handleChange;

  const handleAddSlot = () => {
    if (selectedClassId === "no-value") {
      return swalWarning(messages.selectClass);
    }

    const newTableData = deepCopy(tableData);

    const newSlot = {
      slot: newTableData.at(-1).slot + 1,
      schedules: daysOfWeek.map(day => {
        return { day, ...dummySchedule() };
      }),
    };

    newTableData.push(newSlot);

    setTableData(newTableData);
  };

  // soft delete
  const handleDeleteSlot = slot => {
    const newTableData = deepCopy(tableData);
    newTableData[slot - 1].is_deleted = true;
    setTableData(newTableData);
  };

  const handleSave = async () => {
    if (selectedClassId === "no-value") {
      return swalWarning(messages.selectClass);
    }

    const payload = { regular_class_id: selectedClassId, slots: tableData };

    const callBack = async (resType, data) => {
      if (resType === 1) {
        regularClassSchedule.setList(data.data);
        const tableData = regularClassSchedule.generateScheduleTableData(
          selectedClassId,
          data.data
        );
        setTableData(tableData);
        await swalSuccess(messages.timeTableSaveConfirm);
      } else {
        console.error("Error posting data:", messages.contactMessage);
        await swalError(messages.timeTableSaveError);
      }
    };

    await postClassSchedules(payload, callBack);
  };

  const affiliationOptionList = () => {
    return [
      { id: "no-value", affiliation_name: "クラスの選択" },
      ...regularClass.list,
    ].map(val => {
      return {
        ...val,
        value: val.id,
        label: val.affiliation_name,
      };
    });
  };

  return (
    <>
      <div className="row time-table-container">
        <div className="col-xl-8">
          <div className="card time-table">
            <div className="card-header">
              <div className="row">
                <label className="col-form-label col-md-1 text-md-end text-start affiliation-label">
                  所属
                </label>
                <div className="col-md-6 affiliation-select">
                  <MyBsSelect
                    label=""
                    name="regular_class_id"
                    value={selectedClassId}
                    onChange={handleAffiliationChange}
                    placeholder=""
                  >
                    {generateOptions(affiliationOptionList())}
                  </MyBsSelect>
                </div>
                <div className="col-md-5">
                  <div className="d-flex justify-content-end">
                    <div className="row-number-label">
                      行番号: {tableData.length}
                    </div>
                    <Button
                      className="btn btn-success px-2 ml-2"
                      onClick={handleAddSlot}
                    >
                      + 行を追加
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div ref={tableBodyRef} className="table-responsive flex-grow-1">
              <Table
                striped
                bordered
                className={`mt-2 text-left ${
                  selectedClassId !== "no-value" ? "" : "pe-none"
                }`}
              >
                <thead>
                  <tr>
                    {daysOfWeek.map(day => (
                      <th className="text-center" key={day}>
                        {day}
                      </th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(({ slot, schedules, is_deleted }) => {
                    return is_deleted ? null : (
                      <tr key={slot}>
                        {schedules.map((classItem, index) => {
                          return (
                            <td key={index} className="text-center">
                              <div>
                                <TimePickerField
                                  name="start_time"
                                  value={classItem?.start_time || null}
                                  onChange={value =>
                                    handleTimeChange(
                                      value,
                                      "start_time",
                                      slot,
                                      index
                                    )
                                  }
                                />
                                <div style={{ fontSize: "10px" }}>|</div>
                                <TimePickerField
                                  name="end_time"
                                  value={classItem?.end_time || null}
                                  onChange={value =>
                                    handleTimeChange(
                                      value,
                                      "end_time",
                                      slot,
                                      index
                                    )
                                  }
                                />
                              </div>
                              <br />
                              <div className="table-select">
                                コースレベル
                                <MyBsSelect
                                  label=""
                                  name="max_student"
                                  value={classItem.max_student || "no-value"}
                                  onChange={e =>
                                    handleSelectChange(e, slot, index)
                                  }
                                  placeholder=""
                                >
                                  {generateOptions(
                                    classLevel.getOptionList(selectedClassId)
                                  )}
                                </MyBsSelect>
                              </div>
                              <div className="mt-1 table-select">
                                コーチ
                                <MyBsSelect
                                  label=""
                                  name="coach_id"
                                  value={classItem.coach_id || "no-value"}
                                  onChange={e =>
                                    handleSelectChange(e, slot, index)
                                  }
                                  placeholder=""
                                >
                                  {generateOptions(coach.getOptionList())}
                                </MyBsSelect>
                              </div>
                            </td>
                          );
                        })}
                        <td>
                          <button
                            className="btn btn-sm btn-sm-custom btn-danger"
                            style={{ marginLeft: "8px" }}
                            onClick={e => {
                              handleDeleteSlot(slot);
                            }}
                          >
                            <FontAwesomeIcon icon="trash" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="card-footer">
              <div className="text-end">
                <RedirectButton
                  redirectTo="/admin/master-maintenance/dashboard"
                  className="btn btn-primary mr-5 text-align-center"
                >
                  戻る
                </RedirectButton>
                <Button
                  className="btn btn-primary mx-2"
                  type="submit"
                  onClick={handleSave}
                >
                  保存する
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseTime;
