import { useEffect, useState } from "react";
import TuitionFeesContext from "src/context/tuitionFeesContext.js";
import { tuitionFees } from "src/services/privateApi";
import messages from "src/utils/messages";
import { swalError } from "src/utils/swalHelpers";

const TuitionFeesProvider = ({ children, ...props }) => {
  const [list, setList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch tuition fees
        const tuitionFeesResponse = await tuitionFees();
        if (tuitionFeesResponse.resType === 1) {
          setList(tuitionFeesResponse.data.data);
        } else if (tuitionFeesResponse.resType === 2) {
          await swalError(messages.tuitionInfoNotFound);
        } else if (tuitionFeesResponse.resType === 3) {
          await swalError(tuitionFeesResponse.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        await swalError(messages.contactMessage);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchData();
  }, []);

  // note praveen: used kaisuu since i couldnt come up with a better name for it, feel free to change it if you wish to
  const kaisuuOptionsList = regularClassId => {
    return list
      .filter(val => val.regular_class_id === regularClassId)
      .map(val => {
        return { ...val, value: val.times, label: val.times };
      });
  };

  const contextValues = {
    list,
    isLoaded,
    kaisuuOptionsList,
  };

  return (
    <TuitionFeesContext.Provider value={contextValues}>
      {children}
    </TuitionFeesContext.Provider>
  );
};

export default TuitionFeesProvider;
