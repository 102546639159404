import { Formik } from "formik";
import { Form } from "react-bootstrap";
import MyInput from "src/components/common/FormElements/MyInput";

const RankPageFilter = ({
  monthsInfo,
  searchInputRef,
  toggleSelectedMonth,
  fetchMemberData,
}) => {
  const renderFormCheck = monthInfo => {
    return (
      <Form.Check
        inline
        label={monthInfo.label}
        name="group1"
        type="radio"
        id="inline-radio-1"
        onChange={() => toggleSelectedMonth(monthInfo.label)}
        checked={monthInfo.isSelected}
      />
    );
  };

  return (
    <div className="filter-wrapper">
      <div className="col-md-12 d-flex">
        <div className="d-flex align-items-center mb-2 col-md-4">
          <span>処理区分：</span>
          <div className="border radio-button-container">
            <Form>
              {monthsInfo.map(monthInfo => {
                return renderFormCheck(monthInfo);
              })}
            </Form>
          </div>
        </div>
        <Formik
          initialValues={{ member_code: "" }}
          enableReinitialize={true}
          onSubmit={async (formData, { setSubmitting }) => {
            setSubmitting(true);
            await fetchMemberData(formData.member_code);
            setSubmitting(false);
          }}
        >
          {formik => {
            const disabled = formik.isSubmitting;

            return (
              <div className="d-flex align-items-center mb-2 col-md-4 member-search">
                <Form id="search-form" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-3 text-md-end pt-1">会具番号：</div>
                    <div className="col-5">
                      <MyInput
                        label=""
                        name="member_code"
                        type="text"
                        placeholder=""
                        disabled={disabled}
                        inputRef={searchInputRef}
                        autoComplete="off"
                        selectTextOnFocus={true}
                        onKeyDown={e => {
                          if (e.code === "Tab" && !e.shiftKey) {
                            e.preventDefault();
                            formik.submitForm();
                          }
                        }}
                      />
                    </div>
                    <div className="col-3">
                      <button
                        disabled={disabled}
                        className="btn btn-primary w-100"
                        type="submit"
                        tabIndex={-1}
                      >
                        検索
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default RankPageFilter;
